import { isBrowser } from "../general"
import { generateJWT } from "services/jwt"

import axios from "axios"

export const firestore = axios.create({
  timeout: 10000,
  baseURL: process.env.GATSBY_FIRESTORE_CLOUD_FUNCTION,
  headers: {
    "Content-Type": "application/json",
  },
})

export const firebaseApi = ({ accessToken }) => {
  if (isBrowser()) {
    let api = axios.create({
      baseURL: `https://firestore.googleapis.com/v1/projects/${process.env.GATSBY_FIREBASE_PROJECT_ID}/databases/(default)/documents/`,
      headers: {
        Authorization: "Bearer " + (accessToken || ""),
        "Content-Type": "application/json",
      },
    })
    return api
  }
  return null
}

export const queryDocuments = async ({ structuredQuery }) => {
  const JWT_OBJECT = await generateJWT()
  const ACCESS_TOKEN = JWT_OBJECT?.data?.access_token

  let documents = await firebaseApi({ accessToken: ACCESS_TOKEN }).post(
    ":runQuery",
    structuredQuery
  )
  // documents = FireStoreParser(documents?.data)

  return documents
}
